import React from "react";

const openContact = () => {
  document.querySelector(".icon-menu .icon-box:nth-child(3)").click();  
};


const PersonalInfo = () => {
  return (
    <>
      <span className="value d-block mb-3 info">
      Alex Casassovici, a recognized tech visionary, accomplished entrepreneur, and committed environmental advocate, currently serves as the CEO and founder of <a href="https://azarus.io" target="_blank" rel="noreferrer">Azarus</a>, the "Web3 Layer for Streams". Azarus was recently acquired by <a href="https://www.animocabrands.com" target="_blank" rel="noreferrer">Animoca Brands</a>, a global leader in blockchain, gamification, and digital entertainment.
      </span>
      <span className="value d-block mb-3 info">
      In his entrepreneurial journey, Alex has achieved four successful exits and secured over $20 million in funding for his ventures. He founded Azarus in 2018, a platform that has distributed over $2 million in rewards through its $AZA token and digital items. In the last year alone, Azarus reached over 20 million viewers and established partnerships with key industry players.
      </span>
      <span className="value d-block mb-3 info">
      Alex is a prolific public speaker, having delivered more than 30 keynote addresses on technology, with a focus on blockchain, gaming, advertising, and sports.
      </span>
      <span className="value d-block mb-3 info">
      Beyond his professional pursuits, Alex is an adventurer who enjoys trail running, scuba diving, and solving puzzles. He is actively involved in marine conservation through <a href="https://diveboard.com" target="_blank" rel="noreferrer">Diveboard</a>, the largest marine citizen science platform.
      </span>
      <span className="value d-block mb-3 info">
      Connect with Alex Casassovici for speaking engagements, investment opportunities, Azarus sponsorships, and more.
      </span>

      <button className="button" onClick={openContact}>
        <span className="button-text">Contact Me</span>
        <span className="button-icon fa fa-arrow-right"></span>
      </button>
    </>
  );
};

export default PersonalInfo;
