import React from "react";
import heroImg from "../../assets/img/hero/alex.jpg";
import heroImgMobile from "../../assets/img/hero/alex_square.jpg";


const heroContent = {
  heroImage: heroImg,
  heroMobileImage: heroImgMobile,
  heroTitleName: "Alex Casassovici",
  heroDesignation: "WEB3, GAMING & MEDIA INNOVATOR, ENTREPRENEUR, AND STRATEGIC LEADER",
  heroDescriptions: `CEO of Azarus.io. 4x Exits. $20M+ Raised. 30+ Keynotes. 20M+ Viewers.`,
  heroBtn: "more about me",
};

const Hero = () => {
  function openAbout() {
    // simulates a click on teh about Tab in the menu
    document.querySelector(".icon-menu .icon-box:nth-child(2)").click();  
  } 

  return (
    <>
      <div className="row home-details-container align-items-center">
        <div
          className="col-lg-4 bg position-fixed d-none d-lg-block"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + heroContent.heroImage
            })`,
          }}
        ></div>
        <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
          <div>
            <img
              src={heroContent.heroMobileImage}
              className="img-fluid main-img-mobile d-sm-block d-lg-none"
              alt="hero man"
            />
            <h1 className="text-uppercase poppins-font">
              {heroContent.heroTitleName}
              <span>{heroContent.heroDesignation}</span>
            </h1>
            <p className="open-sans-font">{heroContent.heroDescriptions}</p>
            <button className="button" onClick={openAbout}>
              <span className="button-text">{heroContent.heroBtn}</span>
              <span className="button-icon fa fa-arrow-right"></span>
            </button>
          </div>
        </div>
      </div>
      {/* End home-details-container */}
    </>
  );
};

export default Hero;
