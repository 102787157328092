import React from "react";
import Achievements from "./Achievements";

import PersonalInfo from "./PersonalInfo";
import heroImgMobile from "../../assets/img/hero/alex_square.jpg";
import PersonalLinks from "./PersonalLinks";	

const index = () => {
  return (
    <section className="main-content ">
      <div className="container">
        <div className="row">
          {/* Personal Info Starts */}

          <div className="col-xl-6 col-lg-5 col-12">
            <div className="row">
              <div className="col-12 d-block d-sm-none">
                <img
                  src={heroImgMobile}
                  className="img-fluid main-img-mobile"
                  alt="about avatar"
                />
              </div>
              {/* image for mobile menu */}

              <div className="col-12">
                <PersonalInfo />
              </div>
              {/* End personal info */}
            </div>
          </div>
          {/*  Personal Info Ends */}

          {/*  Boxes Starts */}
          <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
            <Achievements />
            <PersonalLinks />
          </div>
          {/* Achievements Ends */}
        </div>
        {/* End .row */}
      </div>
    </section>
  );
};

export default index;
